// src/components/Reports.js
import React, { useState, useContext, useEffect } from 'react';
import { MQTTContext } from './MQTTConn';
import {
  Container,
  Typography,
  Box,
  Grid,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Alert,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  InputLabel,
  TextField,
} from '@mui/material';
import { Description, PictureAsPdf } from '@mui/icons-material';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFilePdf, faSpinner } from '@fortawesome/free-solid-svg-icons';
import './Reports.css'; // Stylování komponenty

const Reports = () => {
  const { client, isConnected, reports } = useContext(MQTTContext);
  const [selectedReportType, setSelectedReportType] = useState('basic'); // 'basic' nebo 'advanced'
  const [selectedType, setSelectedType] = useState('monthly'); // 'monthly' nebo 'yearly' pro pokročilé
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [reportFormat, setReportFormat] = useState('excel');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const handleGenerateReport = () => {
    // Validace vstupů
    if (selectedReportType === 'basic') {
      if (!startDate || !endDate) {
        setMessage({ type: 'error', text: 'Prosím vyberte start a end datum.' });
        return;
      }
    } else if (selectedReportType === 'advanced') {
      if (selectedType === 'monthly' && !selectedMonth) {
        setMessage({ type: 'error', text: 'Prosím vyberte měsíc.' });
        return;
      }
    }

    if (!selectedYear) {
      setMessage({ type: 'error', text: 'Prosím vyberte rok.' });
      return;
    }

    setLoading(true);
    setMessage(null);

    let payload = {
      action: 'generateReport',
      reportType: selectedReportType,
      year: selectedYear.toString(),
      format: reportFormat,
    };

    if (selectedReportType === 'basic') {
      payload.startDate = startDate;
      payload.endDate = endDate;
    } else if (selectedReportType === 'advanced') {
      if (selectedType === 'monthly') {
        payload.month = selectedMonth;
      }
    }

    const payloadString = JSON.stringify(payload);

    if (client && isConnected) {
      client.publish('reports/request', payloadString, (err) => {
        setLoading(false);
        if (err) {
          setMessage({ type: 'error', text: 'Chyba při odesílání požadavku.' });
        } else {
          setMessage({ type: 'success', text: 'Požadavek na generování reportu byl odeslán.' });
        }
      });
    } else {
      setLoading(false);
      setMessage({ type: 'error', text: 'MQTT klient není připojen.' });
    }
  };

  // Generování seznamu měsíců pro dropdown
  const months = [
    { value: '01', label: 'Leden' },
    { value: '02', label: 'Únor' },
    { value: '03', label: 'Březen' },
    { value: '04', label: 'Duben' },
    { value: '05', label: 'Květen' },
    { value: '06', label: 'Červen' },
    { value: '07', label: 'Červenec' },
    { value: '08', label: 'Srpen' },
    { value: '09', label: 'Září' },
    { value: '10', label: 'Říjen' },
    { value: '11', label: 'Listopad' },
    { value: '12', label: 'Prosinec' },
  ];

  // Generování seznamu let od 2020 do aktuálního roku + 5 let dopředu
  const years = Array.from({ length: 10 }, (_, i) => 2020 + i);

  return (
    <Container maxWidth="md" sx={{ mt: 5, mb: 5 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom align="center">
          Generování Reportů
        </Typography>
        <Box component="form" noValidate autoComplete="off">
          <Grid container spacing={3}>
            {/* Výběr typu reportu */}
            <Grid item xs={12} sm={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Typ Reportu</FormLabel>
                <RadioGroup
                  row
                  aria-label="report-type"
                  name="report-type"
                  value={selectedReportType}
                  onChange={(e) => setSelectedReportType(e.target.value)}
                >
                  <FormControlLabel value="basic" control={<Radio />} label="Základní" />
                  <FormControlLabel value="advanced" control={<Radio />} label="Pokročilý" />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* Podmíněné zobrazení podle typu reportu */}
            {selectedReportType === 'basic' ? (
              <>
                {/* Výběr start date */}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      label="Start Datum"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={startDate || ''}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                {/* Výběr end date */}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      label="End Datum"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={endDate || ''}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </FormControl>
                </Grid>
              </>
            ) : (
              <>
                {/* Výběr typu pokročilého reportu (měsíční/roční) */}
                <Grid item xs={12} sm={6}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Pokročilý Typ Reportu</FormLabel>
                    <RadioGroup
                      row
                      aria-label="advanced-report-type"
                      name="advanced-report-type"
                      value={selectedType}
                      onChange={(e) => setSelectedType(e.target.value)}
                    >
                      <FormControlLabel value="monthly" control={<Radio />} label="Měsíční" />
                      <FormControlLabel value="yearly" control={<Radio />} label="Roční" />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                {/* Výběr měsíce (viditelný pouze při výběru měsíčního reportu) */}
                {selectedType === 'monthly' && (
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel id="month-label">Měsíc</InputLabel>
                      <Select
                        labelId="month-label"
                        id="month-select"
                        value={selectedMonth}
                        label="Měsíc"
                        onChange={(e) => setSelectedMonth(e.target.value)}
                      >
                        {months.map((month) => (
                          <MenuItem key={month.value} value={month.value}>
                            {month.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </>
            )}

            {/* Výběr roku */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="year-label">Rok</InputLabel>
                <Select
                  labelId="year-label"
                  id="year-select"
                  value={selectedYear}
                  label="Rok"
                  onChange={(e) => setSelectedYear(e.target.value)}
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Výběr formátu reportu */}
            <Grid item xs={12} sm={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Formát Reportu</FormLabel>
                <RadioGroup
                  row
                  aria-label="report-format"
                  name="report-format"
                  value={reportFormat}
                  onChange={(e) => setReportFormat(e.target.value)}
                >
                  <FormControlLabel
                    value="excel"
                    control={<Radio />}
                    label={
                      <Box display="flex" alignItems="center">
                        <Description sx={{ mr: 1 }} /> Excel
                      </Box>
                    }
                  />
                  <FormControlLabel
                    value="pdf"
                    control={<Radio />}
                    label={
                      <Box display="flex" alignItems="center">
                        <PictureAsPdf sx={{ mr: 1 }} /> PDF
                      </Box>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* Tlačítko pro generování reportu */}
            <Grid item xs={12} align="center">
              <motion.button
                className="generate-button"
                onClick={handleGenerateReport}
                disabled={loading}
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.2 }}
              >
                {loading ? (
                  <>
                    <FontAwesomeIcon icon={faSpinner} spin /> Generuji...
                  </>
                ) : (
                  'Vygenerovat Report'
                )}
              </motion.button>
            </Grid>

            {/* Zobrazení zpráv o stavu */}
            {message && (
              <Grid item xs={12}>
                <Alert severity={message.type}>{message.text}</Alert>
              </Grid>
            )}
          </Grid>
        </Box>
      </Paper>

      {/* Historie Reportů */}
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          Historie Reportů
        </Typography>
        {reports && reports.length > 0 ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Typ Reportu</TableCell>
                  <TableCell>Měsíc</TableCell>
                  <TableCell>Rok</TableCell>
                  <TableCell>Formát</TableCell>
                  <TableCell>Datum Generování</TableCell>
                  <TableCell>Akce</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reports.map((report, index) => (
                  <TableRow key={index}>
                    <TableCell>{report.reportType === 'basic' ? 'Základní' : 'Pokročilý'}</TableCell>
                    <TableCell>
                      {report.reportType === 'advanced' && report.type === 'monthly'
                        ? months.find((m) => m.value === report.month)?.label
                        : report.reportType === 'basic'
                        ? `${report.startDate} - ${report.endDate}`
                        : '-'}
                    </TableCell>
                    <TableCell>{report.year}</TableCell>
                    <TableCell>{report.format.toUpperCase()}</TableCell>
                    <TableCell>{new Date(report.generatedAt).toLocaleString()}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        href={report.downloadLink}
                        download
                      >
                        Stáhnout
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body1">Žádné reporty nejsou k dispozici.</Typography>
        )}
      </Paper>
    </Container>
  );
};

export default Reports;
