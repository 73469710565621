import React, {useContext, useId, useState} from 'react';
import { MQTTContext } from './MQTTConn';
import './SpotMarket.css';
import { Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ComposedChart, Line, Legend } from 'recharts';

function formatDate(date) {
  var copiedDate = new Date(date);

  let day = date.getDate().toString().padStart(2, '0');
  let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Měsíc je zero-based, takže přidáme 1
  let year = date.getFullYear();

  //copiedDate.setTime(copiedDate.getTime() - (60*60*1000));
  copiedDate.setHours(copiedDate.getHours()-1);

  let copiedDay = copiedDate.getDate().toString().padStart(2, '0');
  let copiedMonth = (copiedDate.getMonth() + 1).toString().padStart(2, '0'); // Měsíc je zero-based, takže přidáme 1
  let copiedYear = copiedDate.getFullYear();

  if (date.getDate() === copiedDate.getDate() && date.getMonth() === copiedDate.getMonth() && date.getFullYear() === copiedDate.getFullYear())
    return `${day}.${month}.${year} ${copiedDate.getHours()}:00 - ${date.getHours()}:00`;
  else
    return `${copiedDay}.${copiedMonth}.${copiedYear} ${copiedDate.getHours()}:00 - ${day}.${month}.${year} ${date.getHours()}:00`;
}

function formatTime(timestamp) {
  var timestampDate = new Date(timestamp);
  var copiedTimestampDate = new Date(timestamp);

  copiedTimestampDate.setHours(copiedTimestampDate.getHours()-1);

  return `${copiedTimestampDate.getHours()}:00 - ${timestampDate.getHours()}:00`
}

const CustomTooltip = ({ active, payload, label }) => {

  console.log(payload);

  if (active && payload && payload.length) {
    let selected_time = new Date(label);
    return (
      <div className="prediction-chart-tooltip">
        <p className="label">{ formatDate(selected_time) }</p>
        <p className="data">Cena za MWh: {payload[0].value.toFixed(2)} {payload[0].unit}</p>
        { payload.length > 1 && <p className="data-real">Cena za MWh při nákupu: {payload[1].value.toFixed(2)} {payload[1].unit}</p> }
      </div>
    );
  }

  return null;
};

function BarGradient(props) {
  const id = useId();
  const gradientId = `gradient-${id}`;
  const clipPathId = `clipPath-${id}`;

  const bar_max = 100 - Math.round((props.height / props.background.height) * 100);


  return (
    <>
      <defs>
        <linearGradient id={gradientId} x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset={`${bar_max}%`} stopColor="#e96323" stopOpacity="1"/>
          <stop offset="100%" stopColor="#e96323" stopOpacity="0.1" />
        </linearGradient>

        <clipPath id={clipPathId}>
          <Rectangle {...props} />
        </clipPath>
      </defs>

      <rect
        x={props.x}
        width={props.width}
        height={props.background?.height}
        fill={`url(#${gradientId})`}
        y={props.background?.y}
        clipPath={`url(#${clipPathId})`
      }
      />
    </>
  );
}

function ActiveBarGradient(props) {
  const id = useId();
  const gradientId = `gradient-${id}`;
  const clipPathId = `clipPath-${id}`;

  const bar_max = 100 - Math.round((props.height / props.background.height) * 100);


  return (
    <>
      <defs>
        <linearGradient id={gradientId} x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset={`${bar_max}%`} stopColor="#d51317" stopOpacity="1"/>
          <stop offset="100%" stopColor="#e96323" stopOpacity="0.1" />
        </linearGradient>

        <clipPath id={clipPathId}>
          <Rectangle {...props} />
        </clipPath>
      </defs>

      <rect
        x={props.x}
        width={props.width}
        height={props.background?.height}
        fill={`url(#${gradientId})`}
        y={props.background?.y}
        clipPath={`url(#${clipPathId})`
      }
      />
    </>
  );
}

const hourTickFormatter = (tick) => {
  const date = new Date(tick);

  return `${date.getHours()}:00`;
};

const SpotMarket = () => {

  const mqttData = useContext(MQTTContext);

  const [czkGraph, setCzkGraph] = useState(false);

  return (
    <div class="spot-container">
      <div class="spot-grid">
        <div class="spot-grid-item">
          <div class="spot-vertical-center">
            <h2>{mqttData.dateData.today !== undefined && mqttData.dateData.today.day !== undefined && mqttData.dateData.today.date !== undefined ? `${mqttData.dateData.today.day.toUpperCase()} - ${mqttData.dateData.today.date}` : 'Načítám...'}</h2>
            <h4>{mqttData.actualSpotPriceData.Timestamp !== undefined ? formatTime(mqttData.actualSpotPriceData.Timestamp) : 'Načítám...' }</h4>
            <p>
              {mqttData.actualSpotPriceData.Import_Spot_Price !== undefined ? `${mqttData.actualSpotPriceData.Import_Spot_Price} EUR/MWh` : 'Načítám...' }
            </p>
            <p>
            {mqttData.actualSpotPriceData.Import_Spot_Price_CZK !== undefined ? `${mqttData.actualSpotPriceData.Import_Spot_Price_CZK} CZK/MWh` : 'Načítám...' }
            </p>
          </div>
        </div>
        <div class="spot-grid-second">
          <div class="spot-grid-item-second spot-grid-item-second-top">
            <h3>PRŮMĚRNÁ CENA ZA 24 HOD</h3>
            <p>{mqttData.actualSpotPriceData.AVG_Spot_Price_Import_Day !== undefined ? `${mqttData.actualSpotPriceData.AVG_Spot_Price_Import_Day} EUR/MWh` : 'Načítám...' }</p>
          </div>
          <div class="spot-grid-item-second spot-grid-item-second-top">
            <h3>PRŮMĚRNÁ CENA ZA TÝDEN</h3>
            <p>{mqttData.summarySpotPriceData.AVG_Spot_Price_Import_Week !== undefined ? `${mqttData.summarySpotPriceData.AVG_Spot_Price_Import_Week} EUR/MWh` : 'Načítám...' }</p>
          </div>
          <div class="spot-grid-item-second spot-grid-item-second-bottom">
            <h3>PRŮMĚRNÁ CENA ZA MĚSÍC</h3>
            <p>{mqttData.summarySpotPriceData.AVG_Spot_Price_Import_Month !== undefined ? `${mqttData.summarySpotPriceData.AVG_Spot_Price_Import_Month} EUR/MWh` : 'Načítám...' }</p>
          </div>
          <div class="spot-grid-item-second spot-grid-item-second-bottom">
            <h3>PRŮMĚRNÁ CENA ZA ROK</h3>
            <p>{mqttData.summarySpotPriceData.AVG_Spot_Price_Import_Year !== undefined ? `${mqttData.summarySpotPriceData.AVG_Spot_Price_Import_Year} EUR/MWh` : 'Načítám...' }</p>
          </div>
        </div>
      </div>
      
      <div class="spot-wrapper">
        <div class="spot-bar">
          <h2>SPOT MARKET - {mqttData.dateData.today !== undefined && mqttData.dateData.today.date !== undefined ? mqttData.dateData.today.date : 'Načítám...'}</h2>
        </div>
        <div className="spot-bar-image">
          <img src="/images/graph.png" alt="Graph" />
        </div>
        <div class="spot-graph">     
          <div class="spot-item">
            <div class="spot-currency-change">
              <label className="toggle-switch">
                <input type="checkbox" checked={czkGraph} onChange={e => setCzkGraph(e.target.checked)} />
                <span className="switch">
                  <div class="left">EUR</div>
                  <div class="right">CZK</div>
                </span>
              </label>
            </div>
            <ResponsiveContainer width="100%" height={600}>
              <ComposedChart
                width={500}
                height={300}
                data={mqttData.spotPricesData.spotPrices !== undefined ? mqttData.spotPricesData.spotPrices : []}
                margin={{
                  top: 5,
                  right: 20,
                  left: 30,
                  bottom: 5
                }}
              >
                <CartesianGrid strokeDasharray="1 0" vertical={false} opacity={0.5}/>
                <XAxis dataKey="time" tickFormatter={hourTickFormatter}/>
                <YAxis unit={!czkGraph ? " EUR" : " CZK"}/>
                <Tooltip content={<CustomTooltip />}/>
                <Bar name="Cena za MWh" dataKey={!czkGraph ? "price" : "price_czk"} unit={!czkGraph ? "EUR" : "CZK"} fill='#e96323' shape={<BarGradient />} activeBar={<ActiveBarGradient />} />
                <Line name="Cena za MWh při nákupu" type="monotone" dataKey={!czkGraph ? "import_price" : "import_price_czk"} unit={!czkGraph ? "EUR" : "CZK"} stroke="#7dba00" strokeWidth={3} />
                <Legend />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpotMarket;
